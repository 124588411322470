<template>
  <div class="procureInvoiceDetail">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.procureInvoice.id ? '编辑' : '新增' }}采购发票</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="primary" @click="add()" v-if="data.procureInvoice.id == null">保存</el-button>
          <el-button type="primary" @click="setById()" v-if="data.procureInvoice.id != null">保存</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item label="发票日期">
        <el-date-picker style="width: 100%;" v-model="data.procureInvoice.invoiceDate" type="date" />
      </el-form-item>
      <el-form-item label="供应商">
        <el-select :disabled="data.procureInvoice.id" v-model="data.procureInvoice.supplier" filterable>
          <el-option v-for='supplierItem in show.supplierList' :key="supplierItem.id" :label="supplierItem.value" :value="supplierItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="开票数量">
        <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procureInvoice.productQuantity" />
      </el-form-item>
      <el-form-item label="开票金额">
        <el-input-number style="width: 100%;" :controls="false" :precision="2" v-model="data.procureInvoice.amount" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()
const show = reactive({
  supplierList: [],
})
const pageParam = cache.getObject(cache.keys.pageParam + "procureInvoiceDetail")
const data = reactive({
  procureInvoice: pageParam ? JSON.parse(JSON.stringify(pageParam)) : {},
})

api.get('/backend/dict/getByCode', { params: { code: 'supplier' } }).then(res => {
  show.supplierList = res.dictList
})

const add = () => {
  const param = JSON.parse(JSON.stringify(data.procureInvoice))
  param.invoiceDate = util.parseTime(param.invoiceDate, '{y}-{m}-{d}')
  api.post('/backend/procureInvoice/add', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

const setById = () => {
  const param = { id: data.procureInvoice.id }
  param.invoiceDate = util.parseTime(data.procureInvoice.invoiceDate, '{y}-{m}-{d}')
  param.productQuantity = data.procureInvoice.productQuantity
  param.amount = data.procureInvoice.amount
  api.post('/backend/procureInvoice/setById', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

</script>

<style lang="less">
.procureInvoiceDetail {
  margin: auto;
  max-width: 900px;
}
</style>